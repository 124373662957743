import dayjs from "dayjs"
import { LayoutType } from "@tential/ec-gql-schema/master/pageParts"
import type { RecommendProduct } from "~/types/server/api/recommend"

export const useGtagEvent = () => {
  const gtag = useGtag()
  const config = useRuntimeConfig()
  const route = useRoute()
  const isProd = config.public.APP_ENV === "production"

  const trackBannerClick = (parts: LayoutType, uniqKey: string, index: number, alt: string): void => {
    if (!isProd) return
    const partsName = LayoutType[parts]

    const bannerId = alt || `${parts}-${uniqKey}`
    gtag("event", "banner_click", {
      banner_id: bannerId,
      banner_index: index,
      parts_name: partsName,
      timestamp: dayjs().valueOf(),
      path: route.fullPath,
    })
  }

  const trackRecommendProductClick = (product: RecommendProduct) => {
    if (!isProd) return
    gtag("event", "click_recommend_item", {
      recommend_id: product.recommend_id,
      display_type: "sidecart",
      product_id: product.document_id,
    })
  }

  const trackRecommendProductView = (product: RecommendProduct) => {
    if (!isProd) return
    gtag("event", "inview_recommend_item", {
      recommend_id: product.recommend_id,
      display_type: "sidecart",
      product_id: product.document_id,
    })
  }

  return { trackBannerClick, trackRecommendProductClick, trackRecommendProductView }
}
